import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  makeStyles,
} from "@material-ui/core";
import { Box, List, ListItem, Typography } from "@mui/material";
import React from "react";
import { namesOfOrderFields } from "../../../../utils/namesOfOrderFields";
import { getDatabase, ref, set, update } from "firebase/database";
import materials from "../../../../utils/materials";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}));

// анимация мод.окна
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const FullInfoAboutChosenOrder = ({
  openFullOrderInfo,
  setOpenFullOrderInfo,
  fullInfoAboutChosenOrder,
  orderId,
  userUid,
}) => {
  const classes = useStyles();

  const fullOrderInfoArr = !fullInfoAboutChosenOrder
    ? null
    : Object.entries(fullInfoAboutChosenOrder);

  function takeOrder() {
    const database = getDatabase();

    update(ref(database, `orders/${orderId}/`), {
      status: "inWork",
      workerUid: { userUid },
    });

    update(
      ref(
        database,
        `users/${fullInfoAboutChosenOrder.userUid}/userOrders/${orderId}`
      ),
      {
        status: "inWork",
      }
    );

    const idAlert = Date.now();
    const date = new Date().toLocaleString("ru", {
      timeZone: "Europe/Moscow",
    });

    set(
      ref(
        database,
        `users/${fullInfoAboutChosenOrder.userUid}/alerts/${idAlert}`
      ),
      {
        date: date,
        id: "inWork",
        numberOrder: orderId,
      }
    );

    set(ref(database, `users/${userUid}/ordersInWork/${orderId}`), {
      numberOrder: orderId,
    });

    setOpenFullOrderInfo(false);
  }

  return (
    <Box>
      <Dialog
        open={openFullOrderInfo}
        disableEnforceFocus
        PaperProps={{
          style: {
            backgroundColor: "#ffffff",
            boxShadow: "none",
          },
        }}
        TransitionComponent={Transition}
        className={classes.root}
        aria-labelledby="Информация о заказе"
      >
        <DialogTitle id="order_info">Информация о заказе</DialogTitle>
        <DialogContent>
          <Typography>
            Наименование проекта:{" "}
            <strong>{fullInfoAboutChosenOrder?.name}</strong>
          </Typography>
          <List className={classes.root}>
            {fullOrderInfoArr?.map((item) =>
              item[0] === "totalLength" ? (
                <ListItem key={item[0]}>{`${
                  namesOfOrderFields[item[0]]
                }: ${Object.keys(item[1]).map((key) =>
                  [
                    materials[key],
                    Object.entries(item[1][key]).join(", ")
                  ].join(": ")
                )} `}</ListItem>
              ) : item[0] === "description" ||
                item[0] === "finalTime" ||
                item[0] === "fullPrice" ||
                item[0] === "materialPrice" ||
                item[0] === "paintPrice" ||
                item[0] === "workPrice" ? (
                <ListItem key={item[0]}>{`${namesOfOrderFields[item[0]]}: ${
                  item[1]
                }; `}</ListItem>
              ) : item[0] === "photo" ? (
                <ListItem key={item[0]}>
                  {namesOfOrderFields[item[0]]}:
                  <Button
                    style={{ marginLeft: "10px" }}
                    target="_blank"
                    variant="contained"
                    color="secondary"
                    href={item[1]}
                  >
                    Открыть
                  </Button>
                </ListItem>
              ) : null
            )}
          </List>
        </DialogContent>
        <DialogActions>
          <Button variant="text" color="secondary" onClick={takeOrder}>
            Взять заказ
          </Button>
          <Button variant="text" onClick={() => setOpenFullOrderInfo(false)}>
            Закрыть
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default FullInfoAboutChosenOrder;
