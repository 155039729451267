import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  TextField,
  makeStyles,
} from "@material-ui/core";
import { getDatabase, ref, set, update } from "firebase/database";
import { forwardRef, useState } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}));

// анимация мод.окна
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ModalPrepaymentLink = ({
  openModalPrepayment,
  setOpenModalPrepayment,
  orderId,
  fullOrderInfo,
}) => {
  const classes = useStyles();
  const [inpLink, setInpLink] = useState("");

  function getInpLink(inpLink) {
    setInpLink(inpLink);
  }

  function submitLink() {
    const database = getDatabase();
    update(ref(database, `orders/${orderId}/`), {
      linkForPrepayment: inpLink,
      status: "sendForPrepayment",
    });

    update(
      ref(database, `users/${fullOrderInfo.userUid}/userOrders/${orderId}`),
      {
        status: "sendForPrepayment",
      }
    );

    const idAlert = Date.now();
    const date = new Date().toLocaleString("ru", {
      timeZone: "Europe/Moscow",
    });

    set(ref(database, `users/${fullOrderInfo.userUid}/alerts/${idAlert}`), {
      date: date,
      id: "sendForPrepayment",
      numberOrder: orderId,
    });
    setOpenModalPrepayment(false);
  }

  return (
    <>
      <Dialog
        open={openModalPrepayment}
        disableEnforceFocus
        PaperProps={{
          style: {
            backgroundColor: "#ffffff",
            boxShadow: "none",
          },
        }}
        TransitionComponent={Transition}
        className={classes.root}
        aria-labelledby="Ссылка для оплаты"
      >
        <DialogTitle id="linkForPrepayment">Ссылка для оплаты</DialogTitle>
        <DialogContent>
          <input
            value={inpLink}
            onChange={(e) => getInpLink(e.target.value)}
            placeholder="Вставьте ссылку для оплаты"
          />
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={submitLink}>
            Отправить
          </Button>
          <Button variant="text" onClick={() => setOpenModalPrepayment(false)}>
            Закрыть
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ModalPrepaymentLink;
