import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  makeStyles,
} from "@material-ui/core";
import { List, ListItem, Popover, Typography } from "@mui/material";
import React from "react";
import PopupState, { bindPopover, bindTrigger } from "material-ui-popup-state";
import { getDatabase, ref, set, update } from "firebase/database";
import ModalPrepaymentLink from "./ModalPrepaymentLink";
import { namesOfOrderFields } from "../../../../../../../utils/namesOfOrderFields";
import materials from "../../../../../../../utils/materials";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}));

// анимация мод.окна
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const FullOrderSkeletonInfo = ({
  openFullOrderInfo,
  setOpenFullOrderInfo,
  fullOrderInfo,
  orderId,
  adminDeleteOrder,
  openModalPrepayment,
  setOpenModalPrepayment,
}) => {
  const classes = useStyles();

  const fullOrderInfoArr = !fullOrderInfo
    ? null
    : Object.keys(fullOrderInfo).map(function (key) {
        return [key, fullOrderInfo[key]];
      });

  // изменение статуса заказа

  function changeStatus(e) {
    const buttonKey = e.currentTarget.id;
    let newStatus = "disputed";

    const database = getDatabase();

    if (buttonKey === "orderIsReady") {
      newStatus = "orderIsReady";
    }
    if (buttonKey === "sendForExecution") {
      newStatus = "sendForExecution";
    }
    if (buttonKey === "refuse") {
      newStatus = "refuse";
    }

    update(ref(database, `orders/${orderId}/`), {
      status: newStatus,
    });

    update(
      ref(database, `users/${fullOrderInfo.userUid}/userOrders/${orderId}`),
      {
        status: newStatus,
      }
    );

    const idAlert = Date.now();
    const date = new Date().toLocaleString("ru", {
      timeZone: "Europe/Moscow",
    });

    set(ref(database, `users/${fullOrderInfo.userUid}/alerts/${idAlert}`), {
      date: date,
      id: newStatus,
      numberOrder: orderId,
    });
  }

  return (
    <Box>
      <Dialog
        open={openFullOrderInfo}
        disableEnforceFocus
        PaperProps={{
          style: {
            backgroundColor: "#ffffff",
            boxShadow: "none",
          },
        }}
        TransitionComponent={Transition}
        className={classes.root}
        aria-labelledby="Информация о заказе"
      >
        <DialogTitle id="order_info">Информация о заказе</DialogTitle>

        <DialogContent>
          <Typography>
            Номер заказа: <strong>{orderId}</strong>
          </Typography>
          <List className={classes.root}>
            {fullOrderInfoArr?.map((item) =>
              item[0] === "totalLength" ? (
                <ListItem key={item[0]}>{`${namesOfOrderFields[item[0]]}: 
                ${Object.keys(item[1]).map((key) =>
                  [
                    materials[key],
                    Object.entries(item[1][key]).join(";  "),
                  ].join(": ")
                )} `}</ListItem>
              ) : (
                <ListItem key={item[0]}>{`${namesOfOrderFields[item[0]]}: ${
                  item[1]
                }; `}</ListItem>
              )
            )}
          </List>
        </DialogContent>
        <DialogActions>
          <PopupState variant="popover" popupId="demo-popup-popover">
            {(popupState) => (
              <div>
                <Button {...bindTrigger(popupState)}>Изменить статус</Button>
                <Popover
                  {...bindPopover(popupState)}
                  disableAutoFocus={true}
                  disableEnforceFocus={true}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <Button
                    id="orderIsReady"
                    onClick={changeStatus}
                    style={{ margin: "10px" }}
                  >
                    Заказ готов
                  </Button>
                  <Button
                    id="sendForPrepayment"
                    onClick={() => setOpenModalPrepayment(true)}
                    style={{ margin: "10px" }}
                  >
                    Отправить на предоплату
                  </Button>
                  <Button
                    id="sendForExecution"
                    onClick={changeStatus}
                    style={{ margin: "10px" }}
                  >
                    Отправить на исполнение
                  </Button>
                  <Button
                    id="refuse"
                    onClick={changeStatus}
                    style={{ margin: "10px" }}
                  >
                    Отказать
                  </Button>
                  <Button onClick={popupState.close} style={{ margin: "10px" }}>
                    Закрыть
                  </Button>
                </Popover>
              </div>
            )}
          </PopupState>

          <PopupState variant="popover" popupId="demo-popup-popover">
            {(popupState) => (
              <div>
                <Button variant="text" {...bindTrigger(popupState)}>
                  Отменить
                </Button>
                <Popover
                  {...bindPopover(popupState)}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <Typography sx={{ p: 2 }}>
                    Подтвердите отмену заказа
                  </Typography>
                  <Button
                    onClick={adminDeleteOrder}
                    style={{ marginLeft: "10px" }}
                  >
                    Отменить
                  </Button>
                  <Button
                    onClick={popupState.close}
                    style={{ float: "right", marginRight: "10px" }}
                  >
                    Закрыть
                  </Button>
                </Popover>
              </div>
            )}
          </PopupState>
          <Button variant="text" onClick={() => setOpenFullOrderInfo(false)}>
            Закрыть
          </Button>
        </DialogActions>
      </Dialog>
      <ModalPrepaymentLink
        openModalPrepayment={openModalPrepayment}
        setOpenModalPrepayment={setOpenModalPrepayment}
        orderId={orderId}
        fullOrderInfo={fullOrderInfo}
      />
    </Box>
  );
};

export default FullOrderSkeletonInfo;
