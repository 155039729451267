import {
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { getDatabase, onValue, ref } from "firebase/database";
import { auth } from "../../../../config/firebase.config";
import OrderedSkeletons from "./allOrderModal/OrderedSkeletons";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}));

// анимация мод.окна
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const UserProfile = ({
  openProfile,
  setOpenProfile,
  email,
  form,
  openSubmitSuccess,
  phoneNumberUser,
}) => {
  const classes = useStyles();
  const [ordersId, setOrdersId] = useState([]);
  const [openOrderedSkeletons, setOpenOrderedSkeletons] = useState(false);

  useEffect(() => {
    form.setValue("email", email);
  }, [openSubmitSuccess, email, form, phoneNumberUser]);

  useEffect(() => {
    form.setValue("phoneNumber", phoneNumberUser);
  }, [phoneNumberUser, form]);

  const userId = auth.currentUser?.uid;
  useEffect(() => {
    const db = getDatabase();
    const ordersId = ref(db, `users/${userId}/userOrders/`);
    onValue(ordersId, (snapshot) => {
      const data = snapshot.val();
      setOrdersId(data);
    });
  }, [userId]);

  return (
    <>
      <Dialog
        open={openProfile}
        PaperProps={{
          style: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            boxShadow: "none",
            width: "100vw",
            maxWidth: "500px",
            margin: 0,
            padding: 0,
          },
        }}
        TransitionComponent={Transition}
        className={classes.root}
        aria-labelledby="Личный кабинет"
      >
        <DialogTitle id="user_profile">Личный кабинет</DialogTitle>
        <form key={3}>
          <DialogContent>
            <Typography variant="h6" style={{ marginBottom: "10px" }}>
              Ваш email: <Button color="secondary">{email}</Button>
            </Typography>
            <Typography variant="h6" style={{ marginBottom: "10px" }}>
              Ваш номер телефона:{" "}
              <Button color="secondary">{phoneNumberUser}</Button>
            </Typography>
            <ButtonGroup>
              <Button
                color="secondary"
                onClick={() => setOpenOrderedSkeletons(true)}
              >
                Заказы каркасов по Вашим проектам
              </Button>
            </ButtonGroup>
          </DialogContent>
          <DialogActions>
            <Button variant="text" onClick={() => setOpenProfile(false)}>
              Закрыть
            </Button>
          </DialogActions>
        </form>
        <OrderedSkeletons
          openOrderedSkeletons={openOrderedSkeletons}
          setOpenOrderedSkeletons={setOpenOrderedSkeletons}
          ordersId={ordersId}
          userId={userId}
        />
      </Dialog>
    </>
  );
};

export default UserProfile;
