import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  makeStyles,
} from "@material-ui/core";
import { getDatabase, ref, set } from "firebase/database";
import { forwardRef, useState } from "react";
import FormAddProduct from "./FormAddProduct";
import SuccessAddProduct from "../../../snackbar/SuccessAddProduct";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}));

// анимация мод.окна
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AddProduct = ({ openAddProduct, setOpenAddProduct, form }) => {
  const classes = useStyles();
  const [openSuccessAddProduct, setOpenSuccessAddProduct] = useState(false);

  const onSubmit = (result) => {
    const database = getDatabase();
    set(ref(database, `products/${result.nameEng}`), {
      category: result.category,
      name: result.name,
      img: result.img,
      price: result.price,
      description: result.description,
    });
    form.reset();
    setOpenSuccessAddProduct(true);
  };

  return (
    <>
      <Dialog
        open={openAddProduct}
        PaperProps={{
          style: {
            backgroundColor: "#ffffff",
            boxShadow: "none",
          },
        }}
        TransitionComponent={Transition}
        className={classes.root}
        aria-labelledby="Добавление товара"
      >
        <DialogTitle id="appeals">Добавление товара</DialogTitle>
        <DialogContent>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <FormAddProduct form={form} />
          </form>
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={() => setOpenAddProduct(false)}>
            Закрыть
          </Button>
        </DialogActions>
      </Dialog>
      <SuccessAddProduct
        openSuccessAddProduct={openSuccessAddProduct}
        setOpenSuccessAddProduct={setOpenSuccessAddProduct}
      />
    </>
  );
};

export default AddProduct;
