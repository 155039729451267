import {
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { getDatabase, onValue, ref } from "firebase/database";
import { forwardRef, useEffect, useState } from "react";
import TableOrders from "../orders/TableOrders";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}));

// анимация мод.окна
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const WorkerProfile = ({ openWorkerProfile, setOpenWorkerProfile, userUid, workerAddress }) => {
  const classes = useStyles();
  const [allOrders, setAllOrders] = useState(null);

  // получение инфы о всех заказах работника
  useEffect(() => {
    const db = getDatabase();
    const orders = ref(db, `users/${userUid}/ordersInWork/`);
    onValue(orders, (snapshot) => {
      const data = snapshot.val();
      setAllOrders(data);
    });
  }, [userUid]);

  return (
    <>
      <Dialog
        open={openWorkerProfile}
        PaperProps={{
          style: {
            boxShadow: "none",
            width: "100vw",
            maxWidth: "500px",
            margin: 0,
            padding: 0,
          },
        }}
        TransitionComponent={Transition}
        className={classes.root}
        aria-labelledby="Заказы"
      >
        <DialogTitle style={{textAlign: "center"}} id="user_profile">Заказы</DialogTitle>
        <form key={3}>
          <DialogContent>
            <TableOrders allOrders={allOrders} workerAddress={workerAddress}/>
          </DialogContent>
          <DialogActions>
            <Button variant="text" onClick={() => setOpenWorkerProfile(false)}>
              Закрыть
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default WorkerProfile;
