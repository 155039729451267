import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  makeStyles,
} from "@material-ui/core";
import React from "react";
import TableOrderedSkeletons from "../table/TableOrderedSkeletons";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}));

// анимация мод.окна
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const OrderedSkeletons = ({
  openOrderedSkeletons,
  setOpenOrderedSkeletons,
  ordersId,
  userId,
}) => {
  const classes = useStyles();

  return (
    <>
      <Dialog
        open={openOrderedSkeletons}
        PaperProps={{
          style: {
            backgroundColor: "#ffffff",
            boxShadow: "none",
          },
        }}
        TransitionComponent={Transition}
        className={classes.root}
        aria-labelledby="Заказы каркасов по Вашим проектам"
      >
        <DialogTitle id="orderedSkeletons">
          Заказы каркасов по Вашим проектам
        </DialogTitle>
        <DialogContent>
          <TableOrderedSkeletons
            ordersId={ordersId}
            userId={userId}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={() => setOpenOrderedSkeletons(false)}>
            Закрыть
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default OrderedSkeletons;
