export const status = {
    disputed: "На рассмотрении",
    orderIsReady: "Заказ готов",
    sendForPrepayment: "Заказ ожидает предоплаты",
    // sendForPrepayment: `Заказ ожидает предоплаты в размере ${
    //     fullOrderProductInfo.finalPrice / 2
    //   } руб. Сбербанк: номер карты 2202 2053 **** ****, получатель: Мария Юрьевна Б., в комментарии к платежу укажите номер Вашего заказа - ${orderId} (это ускорит процесс обработки вашего платежа).`,
    sendForExecution: "Ищем исполнителя",
    refuse: "В данный момент мы не можем выполнить Ваш заказ :(",
    paymentVerification: "Идет проверка платежа",
    inWork: "Ваш заказ выполняется",
    orderReceived: "Заказ получен",
}

