import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  makeStyles,
} from "@material-ui/core";
import { getDatabase, ref, set } from "firebase/database";
import { forwardRef, useEffect, useState } from "react";
import FormChangeProductInfo from "./FOrmChangeProductInfo";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}));

// анимация мод.окна
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ChangeProductInfo = ({
  openChangeProductInfo,
  setOpenChangeProductInfo,
  form,
  fullChangeProductInfo,
  productName,
}) => {
  const classes = useStyles();
  const [isValid, setIsValid] = useState(false);

  const onSubmit = (result) => {
    const database = getDatabase();
    set(ref(database, `products/${productName}`), {
      category: result.category,
      name: result.name,
      img: result.img,
      price: result.price,
      description: result.description,
    });
    form.reset();
    setOpenChangeProductInfo(false);
  };

  const validate = () => {
    const { category, name, img, price, description } = form.getValues();

    if (
      category.length &&
      name.length &&
      img.length &&
      price.length &&
      description.length
    ) {
      setIsValid(
        category.length,
        name.length,
        img.length,
        price.length,
        description.length
      );
      return;
    }
    setIsValid(false);
  };

  useEffect(() => {
    const subscription = form.watch(() => validate());
    return () => subscription.unsubscribe();
  }, [form.watch]);

  return (
    <>
      <Dialog
        open={openChangeProductInfo}
        PaperProps={{
          style: {
            backgroundColor: "#ffffff",
            boxShadow: "none",
          },
        }}
        TransitionComponent={Transition}
        className={classes.root}
        aria-labelledby="Изменение информации о товаре"
      >
        <DialogTitle id="appeals">Изменение информации о товаре</DialogTitle>
        <DialogContent>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <FormChangeProductInfo
              form={form}
              fullChangeProductInfo={fullChangeProductInfo}
              isValid={isValid}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            onClick={() => setOpenChangeProductInfo(false)}
          >
            Закрыть
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ChangeProductInfo;
