import { Alert, Snackbar } from "@mui/material";

const OrderCreateSuccessfully = ({ openOrderCreateSuccessfully, setOpenOrderCreateSuccessfully }) => {
  
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenOrderCreateSuccessfully(false);
  };

  return (
    <div>
      <Snackbar
        open={openOrderCreateSuccessfully}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleClose} severity="success" variant="filled">
          Заказ успешно оформлен. Статус заказа можно посмотреть в личном кабинете.
        </Alert>
      </Snackbar>
    </div>
  );
};

export default OrderCreateSuccessfully;