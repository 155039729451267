import * as React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { useState } from "react";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

function HelpInfo() {
  const [expanded, setExpanded] = useState("");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography>Геолокация</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>Адрес: Республика Марий Эл, д. Нолька</Typography>
          <div style={{ position: "relative", overflow: "hidden" }}>
            <iframe
              src="https://yandex.ru/map-widget/v1/?ll=47.692084,56.641093&mode=search&oid=57618757939&ol=biz&source=serp_navig&z=17.54"
              width="560"
              height="400"
              frameborder="1"
              allowfullscreen="true"
              style={{ position: "relative" }}
            ></iframe>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography>Информация по оформлению заказа</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ul>
            <li style={{ marginBottom: "15px" }}>
              Выполнение заказа начинается с момента получения предоплаты.
            </li>
            <li style={{ marginBottom: "15px" }}>
              Предоплату необходимо внести в течении 24 часов с момента
              получения уведомления о предоплате.
            </li>
            <li style={{ marginBottom: "15px" }}>
              Сроки выполнения заказа могут отличаться от тех, что указаны в
              карточке заказа.
            </li>
            <li style={{ marginBottom: "15px" }}>
              О готовности заказа и месте его получения Вы можете узнать в
              карточке заказа, в личном кабинете.
            </li>
            <li style={{ marginBottom: "15px" }}>
              При онлайн заказе металлокаркасов (по Вашим проектам) обязательно
              добавьте фотографию проекта со всеми его размерами, иначе заказ
              будет отклонен.
            </li>
            <li style={{ marginBottom: "15px" }}>
              По общим вопросам обращайтесь на email:{" "}
              <strong>offers@ymetal.ru</strong> или заполните форму обратной
              связи на главной странице.
            </li>
          </ul>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
      >
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography>Контакты</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>Email: offers@ymetal.ru</Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel4"}
        onChange={handleChange("panel4")}
      >
        <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
          <Typography>О нас</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>О нас</Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default HelpInfo;
