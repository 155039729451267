import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Slide,
  Typography,
} from "@material-ui/core";
import React from "react";
import PortfolioCard from "./PortfolioCard";
import { DialogTitle } from "@mui/material";

// анимация мод.окна
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Portfolio = ({ openPortfolio, setOpenPortfolio, allReadyOrders }) => {
  let readyOrders = [];
  for (let order in allReadyOrders) {
    allReadyOrders[order].order = order;
    readyOrders.push(allReadyOrders[order]);
  }

  return (
    <>
      <Dialog
        open={openPortfolio}
        PaperProps={{
          style: {
            boxShadow: "none",
            height: "100vh",
            width: "100vw",
            maxWidth: "1440px",
            margin: 0,
            padding: 0,
          },
        }}
        TransitionComponent={Transition}
        aria-labelledby="Продукция"
      >
        <form>
          <DialogActions>
            <Button variant="text" onClick={() => setOpenPortfolio(false)}>
              Закрыть
            </Button>
          </DialogActions>
          <DialogTitle style={{ textAlign: "center" }}>Наши работы</DialogTitle>
          <DialogContent>
            {readyOrders.length ? 
            <Grid
             style={{
              display:" grid",
              gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
              gridGap: "20px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              padding: "20px 5px",
            }}
            >
             {readyOrders.map((order) => (
              <PortfolioCard key={order.price} order={order} />
            ))} 
            </Grid> : <Typography style={{textAlign: "center"}} variant="body1">Пока здесь пусто...</Typography>}
          </DialogContent>
        </form>
      </Dialog>
    </>
  );
};

export default Portfolio;
