import "./App.css";
import Main from "./components/pages/main/Main";
import Title from "./components/pages/main/Title";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import SubmitSuccess from "./components/UI/snackbar/SubmitSuccess";
import { auth } from "./config/firebase.config";
import { getDatabase, onValue, ref } from "firebase/database";
import OrderCreateSuccessfully from "./components/UI/snackbar/OrderCreateSuccessfully";
import TenSecondsDelay from "./components/UI/snackbar/TenSecondsDelay";
import { signOut } from "firebase/auth";
import Worker from "./components/pages/worker/Worker";

function App() {
  const [openSubmitSuccess, setOpenSubmitSuccess] = useState(false);
  const [openOrderCreateSuccessfully, setOpenOrderCreateSuccessfully] =
    useState(false);
  const [openApplicationSubmitSuccess, setOpenApplicationSubmitSuccess] =
    useState(false);
  const [openMessageDelay, setOpenMessageDelay] = useState(false);
  const [role, setRole] = useState("");
  const [phoneNumberUser, setPhoneNumberUser] = useState("");
  const [allProductsInfo, setAllProductsInfo] = useState(null);
  const [infoWebConfig, setInfoWebConfig] = useState(null);
  const [orderStatusInfo, setOrderStatusInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [allReadyOrders, setAllReadyOrders] = useState(null);
  const [allPrices, setAllPrices] = useState(null);

  const form = useForm({
    defaultValues: {
      name: "",
      selectedMaterials: [],
      totalLength: {},
      photo: "",
      fullPrice: "",
      description: "",
      phoneNumber: "",
      email: "",
      workPrice: "",
      materialPrice: "",
      paintPrice: "",
      finalTime: "",
      dateCreateOrder: "",
      profilePipe: [],
      roundPipe: [],
      corner: [],
      armature: [],
      sheetMetal: [],
    },
    mode: "onBlur",
  });

  const userUid = auth.currentUser?.uid;

  function userSignOut() {
    signOut(auth)
      .then(() => console.log("success"))
      .catch((e) => console.log(e));
    window.location.reload();
  }

  // получение инфы о пользователе
  useEffect(() => {
    const db = getDatabase();
    const ordersId = ref(db, `users/${userUid}/`);
    onValue(ordersId, (snapshot) => {
      const data = snapshot.val();
      setRole(data?.role);
      setPhoneNumberUser(data?.phoneNumber);
    });
  }, [userUid]);

  // получение инфы о всех продуктах
  useEffect(() => {
    const db = getDatabase();
    const products = ref(db, `products/`);
    onValue(products, (snapshot) => {
      const data = snapshot.val();
      setAllProductsInfo(data);
    });
  }, []);

  // получение инфы о дате последнего заказа каркасов
  useEffect(() => {
    const db = getDatabase();
    const webConfig = ref(db, `webConfig/`);
    onValue(webConfig, (snapshot) => {
      const data = snapshot.val();
      setInfoWebConfig(data);
    });
  }, []);

  // получение инфы о статусе заказа пользователя
  useEffect(() => {
    const db = getDatabase();
    const status = ref(db, `users/${userUid}/alerts/`);
    onValue(status, (snapshot) => {
      const data = snapshot.val();
      setOrderStatusInfo(data);
    });
  }, [userUid]);

  // получение инфы о выполненных заказах(orderIsReady)
  useEffect(() => {
    const db = getDatabase();
    const readyOrders = ref(db, `portfolio/`);
    onValue(readyOrders, (snapshot) => {
      const data = snapshot.val();
      setAllReadyOrders(data);
    });
  }, []);

  // получение инфы о ценах материалов
  useEffect(() => {
    const db = getDatabase();
    const prices = ref(db, `prices/`);
    onValue(prices, (snapshot) => {
      const data = snapshot.val();
      setAllPrices(data);
    });
  }, []);

  return (
    <>
      {role === "worker" ? (
        <Worker userSignOut={userSignOut} userUid={userUid} />
      ) : (
        <>
          <Title />
          <Main
            form={form}
            openSubmitSuccess={openSubmitSuccess}
            role={role}
            phoneNumberUser={phoneNumberUser}
            allProductsInfo={allProductsInfo}
            setOpenSubmitSuccess={setOpenSubmitSuccess}
            infoWebConfig={infoWebConfig}
            setOpenMessageDelay={setOpenMessageDelay}
            userUid={userUid}
            setOpenOrderCreateSuccessfully={setOpenOrderCreateSuccessfully}
            openApplicationSubmitSuccess={openApplicationSubmitSuccess}
            setOpenApplicationSubmitSuccess={setOpenApplicationSubmitSuccess}
            orderStatusInfo={orderStatusInfo}
            userSignOut={userSignOut}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            allReadyOrders={allReadyOrders}
            allPrices={allPrices}
          />
          <SubmitSuccess
            setOpenSubmitSuccess={setOpenSubmitSuccess}
            openSubmitSuccess={openSubmitSuccess}
          />
          <OrderCreateSuccessfully
            openOrderCreateSuccessfully={openOrderCreateSuccessfully}
            setOpenOrderCreateSuccessfully={setOpenOrderCreateSuccessfully}
          />
          <TenSecondsDelay
            openMessageDelay={openMessageDelay}
            setOpenMessageDelay={setOpenMessageDelay}
          />
        </>
      )}
    </>
  );
}

export default App;
