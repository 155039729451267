import { Box, TextField } from "@material-ui/core";
import Element from "./Element";
import PhotoDownloader from "./PhotoDownloader";
import { Typography } from "@mui/material";
import { Controller } from "react-hook-form";
import materials from "../../../../../utils/materials";

const MaterialLength = ({ form }) => {
  form.setValue("totalLength", "");

  const nameMaterials = Object.keys(materials);

  function getValuesMaterials() {
    let values = [];
    for (let i = 0; i < nameMaterials.length; i++) {
      let element = nameMaterials[i];
      values.push([element, form.getValues(`${element}`)]);
    }
    return values;
  }

  return (
    <Box>
      <Typography variant="h6">
        <strong>Введите нужное кол-во материалов:</strong>
      </Typography>

      {getValuesMaterials().map((element) =>
        element[1] === undefined || element[1].length === 0 ? null : (
          <Box key={element[0]}>
            <Typography>{materials[element[0]]}:</Typography>
            <Element element={element} form={form} />
          </Box>
        )
      )}
      <Controller
        control={form.control}
        name="description"
        defaultValue=""
        render={({ field }) => (
          <TextField
            {...field}
            label="Комментарий к проекту"
            variant="filled"
            style={{ width: "100%", marginLeft: 0, marginTop: "30px" }}
          />
        )}
      />
      <PhotoDownloader form={form} />
    </Box>
  );
};

export default MaterialLength;
