import { Box, TextField } from "@material-ui/core";
import { Controller } from "react-hook-form";

const Element = ({ element, form }) => {
  return (
    <Box>
      {element[1] === undefined || element[1].length === 0
        ? null
        : element[1].map((item) => (
            <Controller
              control={form.control}
              key={item}
              defaultValue=""
              name={`totalLength.${element[0]}.${item}`}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={item}
                  variant="filled"
                  style={{ width: "100%", marginLeft: 0 }}
                />
              )}
            />
          ))}
    </Box>
  );
};

export default Element;
