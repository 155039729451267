import * as React from "react";
import Stack from "@mui/material/Stack";
import { LinearProgress } from "@material-ui/core";

function Preloader() {
  return (
    <Stack
      sx={{ width: "100%", height: "36.5px", color: "grey.500" }}
      spacing={2}
    >
      <LinearProgress color="secondary" style={{ marginTop: "12%" }} />
    </Stack>
  );
}

export default Preloader;
