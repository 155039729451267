import { Box, Divider, ListItem, ListItemText } from "@material-ui/core";

const WorkerAddress = ({ workerAddress }) => {

  return (
    <Box>
      <ListItem>
        <ListItemText
          primary="Адресс самовывоза заказа:"
          secondary={workerAddress}
        />
      </ListItem>
      <Divider component="li" />
    </Box>
  );
};

export default WorkerAddress;