import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ListItem,
  Slide,
  makeStyles,
} from "@material-ui/core";
import { List, Popover, Typography } from "@mui/material";
import React from "react";
import FullPrice from "./skeletonsInfo/FullPrice";
import OrderDate from "./skeletonsInfo/OrderDate";
import PaintPrice from "./skeletonsInfo/PaintPrice";
import MaterialPrice from "./skeletonsInfo/MaterialPrice";
import WorkPrice from "./skeletonsInfo/WorkPrice";
import FinalTime from "./skeletonsInfo/FinalTime";
import OrderStatus from "./skeletonsInfo/OrderStatus";
import PopupState, { bindPopover, bindTrigger } from "material-ui-popup-state";
import {
  child,
  get,
  getDatabase,
  ref,
  remove,
  set,
  update,
} from "firebase/database";
import WorkerAddress from "./skeletonsInfo/WorkerAddress";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}));

// анимация мод.окна
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ModalOrderedSkeletonsInfo = ({
  openOrderInfo,
  setOpenOrderInfo,
  fullOrderInfo,
  orderId,
  deleteOrder,
}) => {
  const classes = useStyles();

  function addingCheckStatus() {
    const database = getDatabase();

    update(ref(database, `orders/${orderId}/`), {
      status: "paymentVerification",
    });

    update(
      ref(database, `users/${fullOrderInfo.userUid}/userOrders/${orderId}`),
      {
        status: "paymentVerification",
      }
    );

    const idAlert = Date.now();
    const date = new Date().toLocaleString("ru", {
      timeZone: "Europe/Moscow",
    });

    set(ref(database, `users/${fullOrderInfo.userUid}/alerts/${idAlert}`), {
      date: date,
      id: "paymentVerification",
      numberOrder: orderId,
    });

    setOpenOrderInfo(true);
  }

  // удаление выполненного заказа из профиля пользователя
  const deleteCompletedOrder = async () => {
    const dbRef = ref(getDatabase());

    try {
      const userSnapshot = await get(
        dbRef,
        `users/${fullOrderInfo?.userUid}/userOrders/${orderId}`
      );
      const orderSnapshot = await get(
        dbRef,
        `orders/${orderId}`
      );

      if (userSnapshot.exists() && orderSnapshot.exists()) {
        const userRefToRemove = child(
          dbRef,
          `users/${fullOrderInfo?.userUid}/userOrders/${orderId}`
        );
        const orderRefToRemove = child(
          dbRef,
           `orders/${orderId}`
        );

        await remove(userRefToRemove);
        await remove(orderRefToRemove);
        setOpenOrderInfo(false);
      }
    } catch (error) {
      console.error("Error removing order:", error);
    }
  };

  return (
    <Box>
      <Dialog
        open={openOrderInfo}
        disableEnforceFocus
        PaperProps={{
          style: {
            backgroundColor: "#ffffff",
            boxShadow: "none",
          },
        }}
        TransitionComponent={Transition}
        className={classes.root}
        aria-labelledby="Информация о заказе"
      >
        <DialogTitle id="order_info">Информация о заказе</DialogTitle>
        <DialogContent>
          <Typography>
            Номер заказа: <strong>{orderId}</strong>
          </Typography>
          <List className={classes.root}>
            <OrderDate orderDate={fullOrderInfo?.dateCreateOrder} />
            <MaterialPrice materialPrice={fullOrderInfo?.materialPrice} />
            <PaintPrice paintPrice={fullOrderInfo?.paintPrice} />
            <WorkPrice workPrice={fullOrderInfo?.workPrice} />
            <FullPrice fullPrice={fullOrderInfo?.fullPrice} />
            <FinalTime finalTime={fullOrderInfo?.finalTime} />
            <OrderStatus
              orderStatus={fullOrderInfo?.status}
              price={fullOrderInfo?.workPrice}
            />
            {fullOrderInfo?.status === "orderIsReady" && (
              <WorkerAddress workerAddress={fullOrderInfo?.address} />
            )}
            {fullOrderInfo?.status === "sendForPrepayment" && (
              <ListItem>
                <Button
                  href={fullOrderInfo.linkForPrepayment}
                  target="_blank"
                  color="secondary"
                  variant="outlined"
                  style={{ marginRight: "5px" }}
                >
                  Оплатить
                </Button>
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={addingCheckStatus}
                >
                  Я оплатил
                </Button>
              </ListItem>
            )}
          </List>
        </DialogContent>
        <DialogActions>
          {fullOrderInfo?.status === "orderReceived" ? (
            <Button
              color="secondary"
              variant="outlined"
              onClick={deleteCompletedOrder}
            >
              Удалить
            </Button>
          ) : (
            <PopupState variant="popover" popupId="demo-popup-popover">
              {(popupState) => (
                <div>
                  <Button
                    variant="text"
                    disabled={
                      fullOrderInfo?.status === "paymentVerification" ||
                      fullOrderInfo?.status === "inWork" ||
                      fullOrderInfo?.status === "orderIsReady" ||
                      fullOrderInfo?.status === "sendForExecution"
                    }
                    {...bindTrigger(popupState)}
                  >
                    Отменить
                  </Button>
                  <Popover
                    {...bindPopover(popupState)}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                  >
                    <Typography sx={{ p: 2 }}>
                      Подтвердите отмену заказа
                    </Typography>
                    <Button
                      onClick={deleteOrder}
                      style={{ marginLeft: "10px" }}
                    >
                      Отменить
                    </Button>
                    <Button
                      onClick={popupState.close}
                      style={{ float: "right", marginRight: "10px" }}
                    >
                      Закрыть
                    </Button>
                  </Popover>
                </div>
              )}
            </PopupState>
          )}
          <Button variant="text" onClick={() => setOpenOrderInfo(false)}>
            Закрыть
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ModalOrderedSkeletonsInfo;
