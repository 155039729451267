import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActions } from "@mui/material";

function PortfolioCard({ order }) {
  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardMedia component="img" alt="image" height="200" image={order.photo} />
      <CardContent>
        <Typography variant="body1" color="text.secondary">
          {order.projectName}
        </Typography>
      </CardContent>
      <CardActions style={{ float: "right", margin: "0 5px 10px 0" }}>
        <Typography variant="body" color="secondary">
          Цена под ключ: <strong>{order.price}</strong>
        </Typography>
      </CardActions>
    </Card>
  );
}

export default PortfolioCard;
