import { Alert, Box, Button, ButtonGroup, Grid } from "@mui/material";
import { useState } from "react";
import SignUp from "../../auth/SignUp";
import SignIn from "../../auth/SignIn";
import AuthDetails from "../../auth/AuthDetails";
import NotAuthorized from "../../UI/snackbar/NotAuthorized";
import UserProfile from "../../UI/modal/userProfile/UserProfile";
import AdminProfile from "../../UI/modal/admin/AdminProfile";
import SuccessfulSendingEmail from "../../UI/snackbar/SuccessfulSendingEmail";
import OnlineOrder from "../../UI/modal/order/OnlineOrder";
import Information from "../../UI/modal/information/Information";
import ApplicationForm from "../../UI/modal/feedback/ApplicationForm";
import { useForm } from "react-hook-form";
import { Badge, IconButton, Popover, Typography } from "@material-ui/core";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { auth } from "../../../config/firebase.config";
import PopupState, { bindPopover, bindTrigger } from "material-ui-popup-state";
import {
  child,
  get,
  getDatabase,
  onValue,
  ref,
  remove,
} from "firebase/database";
import Portfolio from "../../UI/modal/portfolio/Portfolio";

const Main = ({
  form,
  openSubmitSuccess,
  role,
  phoneNumberUser,
  allProductsInfo,
  setOpenSubmitSuccess,
  infoWebConfig,
  setOpenMessageDelay,
  openApplicationSubmitSuccess,
  setOpenApplicationSubmitSuccess,
  orderStatusInfo,
  userSignOut,
  isLoading,
  setIsLoading,
  allReadyOrders,
  allPrices,
}) => {
  const [openSignUp, setOpenSignUp] = useState(false);
  const [openSignIn, setOpenSignIn] = useState(false);
  const [openSuccessMessage, setOpenSuccessMessage] = useState(false);
  const [openNotAuth, setOpenNotAuth] = useState(false);
  const [openProfile, setOpenProfile] = useState(false);
  const [openAdminProfile, setOpenAdminProfile] = useState(false);
  const [authUser, setAuthUser] = useState(null);
  const [openOrder, setOpenOrder] = useState(false);
  const [openCollab, setOpenCollab] = useState(false);
  const [openPortfolio, setOpenPortfolio] = useState(false);
  const [openInfo, setOpenInfo] = useState(false);
  const [openApplicationForm, setOpenApplicationForm] = useState(false);

  const feedbackForm = useForm({
    defaultValues: {
      email: "",
      reason: "",
      text: "",
      ticketNumber: "",
      ticketDate: "",
    },
    mode: "onBlur",
  });

  function notificationsLabel(count) {
    if (count === 0) {
      return "no notifications";
    }
    if (count > 99) {
      return "more than 99 notifications";
    }
    return `${count} notifications`;
  }

  let allAlerts = [];
  for (var alert in orderStatusInfo) {
    orderStatusInfo[alert].alert = alert;
    allAlerts.push([
      orderStatusInfo[alert].id,
      orderStatusInfo[alert].numberOrder,
      orderStatusInfo[alert].alert,
      orderStatusInfo[alert].date,
    ]);
  }

  function createAlerts(numberOrder, alertNumber, date) {
    const alerts = {
      orderIsReady: (
        <Alert severity="success" onClose={() => deleteAlert(alertNumber)}>
          Ваш заказ {numberOrder} готов! {date}
        </Alert>
      ),
      sendForPrepayment: (
        <Alert severity="warning" onClose={() => deleteAlert(alertNumber)}>
          Ваш заказ {numberOrder} ожидает предоплаты! {date}
        </Alert>
      ),
      sendForExecution: (
        <Alert severity="info" onClose={() => deleteAlert(alertNumber)}>
          Ваш заказ {numberOrder} ищет исполнителя! {date}
        </Alert>
      ),
      refuse: (
        <Alert severity="error" onClose={() => deleteAlert(alertNumber)}>
          Ваш заказ {numberOrder} отклонен! {date}
        </Alert>
      ),
      paymentVerification: (
        <Alert severity="warning" onClose={() => deleteAlert(alertNumber)}>
          Идет проверка платежа по Вашему заказу {numberOrder}! {date}
        </Alert>
      ),
      inWork: (
        <Alert severity="info" onClose={() => deleteAlert(alertNumber)}>
          Ваш заказ {numberOrder} выполняется! {date}
        </Alert>
      ),
    };
    return alerts;
  }

  const deleteAlert = async (alertNumber) => {
    const dbRef = ref(getDatabase());

    try {
      const alertSnapshot = await get(
        dbRef,
        `users/${auth.lastNotifiedUid}/alerts/${alertNumber}`
      );

      if (alertSnapshot.exists()) {
        const alertRefToRemove = child(
          dbRef,
          `users/${auth.lastNotifiedUid}/alerts/${alertNumber}`
        );

        await remove(alertRefToRemove);
      }
    } catch (error) {
      console.error("Error removing alert:", error);
    }
  };

  return (
    <>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "100vh" }}
      >
        <PopupState variant="popover" popupId="demo-popup-popover">
          {(popupState) => (
            <div>
              {auth.currentUser && (
                <IconButton
                  aria-label={notificationsLabel(100)}
                  style={{
                    position: "absolute",
                    top: "2.5%",
                    right: "5%",
                    zIndex: 100,
                  }}
                  {...bindTrigger(popupState)}
                >
                  <Badge
                    overlap="rectangular"
                    badgeContent={allAlerts.length}
                    color="secondary"
                  >
                    <NotificationsNoneIcon
                      style={{ width: "30px", height: "30px" }}
                      color="primary"
                    />
                  </Badge>
                </IconButton>
              )}
              <Popover
                {...bindPopover(popupState)}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                {allAlerts.length ? (
                  allAlerts.map((item, i) => (
                    <Box key={i}>
                      {createAlerts(item[1], item[2], item[3])[item[0]]}
                    </Box>
                  ))
                ) : (
                  <Typography style={{ margin: "10px" }}>
                    У Вас нет уведомлений
                  </Typography>
                )}
              </Popover>
            </div>
          )}
        </PopupState>
        <Grid style={{ width: "280px" }}>
          <Box>
            <AuthDetails
              authUser={authUser}
              setAuthUser={setAuthUser}
              openSignIn={openSignIn}
              setOpenSignIn={setOpenSignIn}
              setOpenSignUp={setOpenSignUp}
              setOpenProfile={setOpenProfile}
              setOpenAdminProfile={setOpenAdminProfile}
              role={role}
              userSignOut={userSignOut}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />
            <SignUp
              setOpenSignUp={setOpenSignUp}
              openSignUp={openSignUp}
              setOpenSuccessMessage={setOpenSuccessMessage}
            />
            <SignIn setOpenSignIn={setOpenSignIn} openSignIn={openSignIn} />
          </Box>
          <ButtonGroup
            style={{ width: "100%" }}
            size="large"
            orientation="vertical"
            color="primary"
          >
            <Button
              onClick={() =>
                authUser ? setOpenOrder(true) : setOpenNotAuth(true)
              }
            >
              Онлайн заказ
            </Button>
            <Button
              onClick={() =>
                authUser ? setOpenPortfolio(true) : setOpenNotAuth(true)
              }
            >
              Наши работы
            </Button>
            <Button color="warning" onClick={() => setOpenInfo(true)}>
              Информация
            </Button>
            <Button
              color="secondary"
              onClick={() =>
                authUser ? setOpenCollab(true) : setOpenNotAuth(true)
              }
            >
              Обратная связь
            </Button>
          </ButtonGroup>
          <SuccessfulSendingEmail
            openSuccessMessage={openSuccessMessage}
            setOpenSuccessMessage={setOpenSuccessMessage}
          />
          <NotAuthorized
            openNotAuth={openNotAuth}
            setOpenNotAuth={setOpenNotAuth}
          />
          {role === "admin" ? (
            <AdminProfile
              openAdminProfile={openAdminProfile}
              setOpenAdminProfile={setOpenAdminProfile}
              allProductsInfo={allProductsInfo}
            />
          ) : (
            <UserProfile
              openProfile={openProfile}
              setOpenProfile={setOpenProfile}
              email={authUser?.email}
              phoneNumberUser={phoneNumberUser}
              form={form}
              openSubmitSuccess={openSubmitSuccess}
            />
          )}
          <OnlineOrder
            openOrder={openOrder}
            setOpenOrder={setOpenOrder}
            form={form}
            setOpenSubmitSuccess={setOpenSubmitSuccess}
            infoWebConfig={infoWebConfig}
            setOpenMessageDelay={setOpenMessageDelay}
            allPrices={allPrices}
          />
          <Portfolio
            openPortfolio={openPortfolio}
            setOpenPortfolio={setOpenPortfolio}
            allReadyOrders={allReadyOrders}
          />
          <ApplicationForm
            openCollab={openCollab}
            setOpenCollab={setOpenCollab}
            infoWebConfig={infoWebConfig}
            feedbackForm={feedbackForm}
            setOpenMessageDelay={setOpenMessageDelay}
            openApplicationForm={openApplicationForm}
            setOpenApplicationForm={setOpenApplicationForm}
            openApplicationSubmitSuccess={openApplicationSubmitSuccess}
            setOpenApplicationSubmitSuccess={setOpenApplicationSubmitSuccess}
          />
          <Information openInfo={openInfo} setOpenInfo={setOpenInfo} />
        </Grid>
      </Grid>
    </>
  );
};

export default Main;
