import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  Slide,
  makeStyles,
} from "@material-ui/core";
import { DialogActions, DialogContent } from "@mui/material";
import React, { useState } from "react";
import SelectionOfMaterials from "./step2/SelectionOfMaterials";
import MaterialLength from "./step3/MaterialLength";
import FinalSum from "./step4/FinalSum";
import { getDatabase, ref, set } from "firebase/database";
import { auth } from "../../../../config/firebase.config";
import MaterialsAndNameOrder from "./step1/MaterialsAndNameOrder";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}));

// анимация мод.окна
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const OnlineOrder = ({
  openOrder,
  setOpenOrder,
  form,
  setOpenSubmitSuccess,
  infoWebConfig,
  setOpenMessageDelay,
  allPrices,
}) => {
  const classes = useStyles();

  const [step, setStep] = useState(1);
  const [isStepValid, setIsStepValid] = useState(false);

  const dateCreateOrder = new Date().toLocaleString("ru", {
    timeZone: "Europe/Moscow",
  });
  const userUid = auth.currentUser?.uid;

  const lastOrderSkeletonsDate = infoWebConfig?.lastOrderSkeletonsDate?.date;

  const onSubmit = (result) => {
    const database = getDatabase();
    const id = Date.now();

    if (Date.now() > lastOrderSkeletonsDate + 10000) {
      set(ref(database, `orders/${id}`), {
        name: result.name,
        selectedMaterials: result.selectedMaterials,
        totalLength: result.totalLength,
        photo: result.photo,
        description: result.description,
        phoneNumber: result.phoneNumber,
        email: result.email,
        fullPrice: result.fullPrice,
        workPrice: result.workPrice,
        materialPrice: result.materialPrice,
        paintPrice: result.paintPrice,
        finalTime: result.finalTime,
        dateCreateOrder: dateCreateOrder,
        status: "disputed",
        userUid: userUid,
        linkForPrepayment: "",
      });

      set(ref(database, `users/${auth.lastNotifiedUid}/userOrders/${id}`), {
        id: id,
        status: "disputed",
      });

      set(ref(database, `webConfig/lastOrderSkeletonsDate`), {
        date: id,
      });

      form.reset();
      setOpenOrder(false);
      setStep(1);
      setOpenMessageDelay(false);
      setOpenSubmitSuccess(true);
    } else {
      setOpenSubmitSuccess(false);
      setOpenMessageDelay(true);
    }
  };

  const validate = () => {
    const {
      name,
      selectedMaterials,
      profilePipe,
      roundPipe,
      corner,
      armature,
      sheetMetal,
      photo,
      description,
    } = form.getValues();

    if (step === 1) {
      setIsStepValid(name?.length && selectedMaterials?.length);
      return;
    }
    if (step === 2) {
      setIsStepValid(
          profilePipe?.length &&
          roundPipe?.length &&
          corner?.length &&
          armature?.length &&
          sheetMetal?.length
      );
      return;
    }
    if (step === 3) {
      setIsStepValid(photo?.length);
      return;
    }
    setIsStepValid(false);
  };

  React.useEffect(() => {
    const subscription = form.watch(() => validate());
    return () => subscription.unsubscribe();
  }, [form.watch]);

  React.useEffect(() => {
    validate();
  }, [step]);

  return (
    <Box>
      <Dialog
        open={openOrder}
        disableEnforceFocus
        PaperProps={{
          style: {
            display: "flex",
            alignItems: "center",
            boxShadow: "none",
            width: "100vw",
            maxWidth: "500px",
            margin: 0,
            padding: 0,
          },
        }}
        TransitionComponent={Transition}
        className={classes.root}
        aria-labelledby="Онлайн заказ"
      >
        <DialogTitle id="online-registration">
          Онлайн заказ {step}/4
        </DialogTitle>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <DialogContent>
            {step === 1 && <MaterialsAndNameOrder form={form} />}
            {step === 2 && (
              <SelectionOfMaterials form={form} allPrices={allPrices} />
            )}
            {step === 3 && <MaterialLength form={form} />}
            {step === 4 && <FinalSum form={form} allPrices={allPrices} />}
          </DialogContent>
          <DialogActions>
            {step === 2 || step === 3 || step === 4 ? (
              <Button onClick={() => setStep((prev) => prev - 1)}>Назад</Button>
            ) : null}
            {step === 4 && <Button type="submit">Отправить</Button>}
            {(step === 1 || step === 2 || step === 3) && (
              <Button
                disabled={!isStepValid}
                onClick={() => setStep((prev) => prev + 1)}
              >
                Продолжить
              </Button>
            )}
            <Button variant="text" onClick={() => setOpenOrder(false)}>
              Закрыть
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </Box>
  );
};

export default OnlineOrder;
