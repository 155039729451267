import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  Slide,
  TextField,
  makeStyles,
} from "@material-ui/core";
import { DialogActions, DialogContent } from "@mui/material";
import React, { useState } from "react";
import { getDatabase, ref, set } from "firebase/database";
import { Controller } from "react-hook-form";
import ApplicationSubmitSuccess from "../../snackbar/ApplicationSUbmitSuccess";
import { auth } from "../../../../config/firebase.config";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}));

// анимация мод.окна
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ApplicationForm = ({
  openCollab,
  setOpenCollab,
  infoWebConfig,
  setOpenMessageDelay,
  feedbackForm,
  setOpenApplicationSubmitSuccess,
  openApplicationSubmitSuccess,
}) => {
  const classes = useStyles();
  const [isValid, setIsValid] = useState(false);

  const dateCreateTicket = new Date().toLocaleString("ru", {
    timeZone: "Europe/Moscow",
  });

  const lastTicketsDate = infoWebConfig?.lastTicketsDate?.date;

  const onSubmit = (result) => {
    const database = getDatabase();
    const id = Date.now();

    if (Date.now() > lastTicketsDate + 10000) {
      set(ref(database, `tickets/${id}`), {
        email: auth.currentUser.email,
        reason: result.reason,
        text: result.text,
        ticketDate: dateCreateTicket,
      });

      set(ref(database, `webConfig/lastTicketsDate`), {
        date: id,
      });

      feedbackForm.reset();
      setOpenCollab(false);
      setOpenMessageDelay(false);
      setOpenApplicationSubmitSuccess(true);
    } else {
      setOpenApplicationSubmitSuccess(false);
      setOpenMessageDelay(true);
    }
  };

  const validate = () => {
    const { reason, text } = feedbackForm.getValues();

    if (reason.length && text.length) {
      setIsValid(reason.length, text.length);
      return;
    }
    setIsValid(false);
  };

  React.useEffect(() => {
    const subscription = feedbackForm.watch(() => validate());
    return () => subscription.unsubscribe();
  }, [feedbackForm]);

  return (
    <Box>
      <Dialog
        open={openCollab}
        disableEnforceFocus
        PaperProps={{
          style: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            boxShadow: "none",
            width: "100vw",
            maxWidth: "500px",
            margin: 0,
            padding: 0,
          },
        }}
        TransitionComponent={Transition}
        className={classes.root}
        aria-labelledby="Онлайн заказ"
      >
        <DialogTitle id="applicationForm">Обратная связь</DialogTitle>
        <form onSubmit={feedbackForm.handleSubmit(onSubmit)}>
          <DialogContent>
            <Box style={{ display: "grid" }}>
              <Controller
                control={feedbackForm.control}
                name="reason"
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Тема"
                    multiline
                    maxRows={1}
                    variant="filled"
                  />
                )}
              />
              <Controller
                control={feedbackForm.control}
                name="text"
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Обращение"
                    multiline
                    rows={5}
                    variant="filled"
                  />
                )}
              />
            </Box>
          </DialogContent>
          <DialogActions style={{width: "100%", justifyContent: "center"}}>
            <Button type="submit" disabled={!isValid}>
              Отправить
            </Button>
            <Button variant="text" onClick={() => setOpenCollab(false)}>
              Закрыть
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <ApplicationSubmitSuccess
        openApplicationSubmitSuccess={openApplicationSubmitSuccess}
        setOpenApplicationSubmitSuccess={setOpenApplicationSubmitSuccess}
      />
    </Box>
  );
};

export default ApplicationForm;
