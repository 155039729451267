import {
  Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Slide,
    makeStyles,
  } from "@material-ui/core";
  import { forwardRef } from "react";
import AllProductsInfo from "./table/AllProductsInfo";
  
  const useStyles = makeStyles((theme) => ({
    root: {
      "& .MuiTextField-root": {
        margin: theme.spacing(1),
        width: "25ch",
      },
    },
  }));
  
  // анимация мод.окна
  const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  
  const ShowAllProducts = ({ openShowAllProducts, setOpenShowAllProducts, allProductsInfo }) => {
    const classes = useStyles();
  
    return (
      <>
        <Dialog
          open={openShowAllProducts}
          PaperProps={{
            style: {
              backgroundColor: "#ffffff",
              boxShadow: "none",
              height: "100vh",
              width: "100vw",
              maxHeight: "900px",
              maxWidth: "1440px",
              margin: 0,
              padding: 0
            },
          }}
          TransitionComponent={Transition}
          className={classes.root}
          aria-labelledby="Все товары"
        >
          <DialogTitle id="orders">Все товары: {Object.keys(allProductsInfo).length}</DialogTitle>
          <DialogContent>
            <AllProductsInfo allProductsInfo={allProductsInfo}/>
          </DialogContent>
          <DialogActions>
            <Button variant="text" onClick={() => setOpenShowAllProducts(false)}>
              Закрыть
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  };
  
  export default ShowAllProducts;