export const namesOfOrderFields = {
    dateCreateOrder: "Дата оформления заказа",
    description: "Описание",
    email: "Email заказчика",
    finalTime: `Время выполнения заказа(в днях)`,
    fullPrice: "Итоговая стоимость",
    linkForPrepayment: "Ссылка для оплаты заказа",
    materialPrice: "Цена за материалы",
    paintPrice: "Цена за покраску",
    phoneNumber: "Номер телефона заказчика",
    photo: "Фото проекта",
    selectedMaterials: "Выбранные материалы",
    status: "Статус заказа",
    totalLength: "Длина материалов",
    userUid: "Id пользователя",
    workPrice: "Цена работы",
    name: "Наименование проекта",
}