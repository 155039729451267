import Typography from "@mui/material/Typography";
import { Grid, makeStyles } from "@material-ui/core";
import { useState } from "react";
import FullInfoAboutChosenOrder from "./FullInfoAboutChosenOrder";
import OrderCard from "./OrderCard";
import { Box } from "@mui/material";

const useStyles = makeStyles(() => ({
  title: {
    textAlign: "center",
    padding: "10px 0",
    color: "white",
  },
  content: {
    display:" grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
    gridGap: "20px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    padding: "20px 30px",
  },
}));

const Orders = ({
  ordersSendForExecution,
  openFullOrderInfo,
  setOpenFullOrderInfo,
  allOrders,
  userUid,
}) => {
  const classes = useStyles();
  const [orderId, setOrderId] = useState("");
  const [fullInfoAboutChosenOrder, setFullInfoAboutChosenOrder] =
    useState(null);

  function getId(e) {
    const orderId = e.currentTarget.id;
    setOrderId(orderId);
    let fullOrderInfo = allOrders[orderId];
    setFullInfoAboutChosenOrder(fullOrderInfo);
    setOpenFullOrderInfo(true);
  }

  return (
    <>
     <Box sx={{ maxWidth: "100%" }}>
      <Typography variant="h5" className={classes.title}>
        Заказы:
      </Typography>
      <Grid className={classes.content}>
        {ordersSendForExecution?.map((item) => (
          <OrderCard
            key={item[0]}
            getId={getId}
            itemName={item[0]}
            itemValue={item[1]}
          />
        ))}
      </Grid>
      <FullInfoAboutChosenOrder
        openFullOrderInfo={openFullOrderInfo}
        setOpenFullOrderInfo={setOpenFullOrderInfo}
        orderId={orderId}
        fullInfoAboutChosenOrder={fullInfoAboutChosenOrder}
        userUid={userUid}
      />
    </Box>
      </>
  );
};

export default Orders;
