import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  makeStyles,
} from "@material-ui/core";
import { forwardRef } from "react";
import TableOrderedSkeletons from "./table/TableOrderedSkeletons";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}));

// анимация мод.окна
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const OrderedSkeletons = ({
  openOrderedSkeletons,
  setOpenOrderedSkeletons,
  allOrderedSkeletons,
}) => {
  const classes = useStyles();

  return (
    <>
      <Dialog
        open={openOrderedSkeletons}
        PaperProps={{
          style: {
            backgroundColor: "#ffffff",
            boxShadow: "none",
            width: "100vw",
            maxWidth: "1280px",
            margin: 0,
            padding: 0,
          },
        }}
        TransitionComponent={Transition}
        className={classes.root}
        aria-labelledby="Заказы"
      >
        <DialogTitle id="orders">Заказы каркасов</DialogTitle>
        <DialogContent>
          <TableOrderedSkeletons
            allOrderedSkeletons={allOrderedSkeletons}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={() => setOpenOrderedSkeletons(false)}>
            Закрыть
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default OrderedSkeletons;
