import { Box, Typography } from "@mui/material";
import MaterialItem from "./MaterialItem";

const SelectionOfMaterials = ({ form, allPrices }) => {
  const selectedMaterials = form.getValues("selectedMaterials");

  return (
    <Box>
      <Typography variant="h6" style={{marginBottom: "10px"}}><strong>Выберите размеры материалов:</strong></Typography>
      {selectedMaterials.map((item) => (
        <MaterialItem
          key={item}
          form={form}
          item={item}
          allPrices={allPrices}
        />
        
      ))}
    </Box>
  );
};

export default SelectionOfMaterials;
