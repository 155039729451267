import { onAuthStateChanged, signOut } from "firebase/auth";
import { useEffect, useState } from "react";
import { auth } from "../../config/firebase.config";
import { Box } from "@mui/material";
import ButtonSignIn from "../UI/button/ButtonSignIn";
import ButtonSignUp from "../UI/button/ButtonSignUp";
import ButtonUnlogin from "../UI/button/ButtonUnlogin";
import ButtonProfile from "../UI/button/ButtonProfile";
import ButtonAdminProfile from "../UI/button/ButtonAdminProfile";
import Preloader from "../UI/Preloader";

const AuthDetails = ({
  openSignIn,
  setOpenSignIn,
  setOpenSignUp,
  authUser,
  setAuthUser,
  setOpenProfile,
  setOpenAdminProfile,
  role,
  userSignOut,
  isLoading,
  setIsLoading,
}) => {

  useEffect(() => {
    const listen = onAuthStateChanged(auth, (user) => {
      setOpenSignIn(false);

      if (auth.currentUser?.emailVerified === true) {
        setAuthUser(user);
        setIsLoading(false);
      } else {
        setAuthUser(null);
        setIsLoading(false);
      }
    });
    return () => listen();
  }, []);

  // function userSignOut() {
  //   signOut(auth)
  //     .then(() => console.log("success"))
  //     .catch((e) => console.log(e));
  // }

  return (
    <div>
      {isLoading ? (
        <Preloader />
      ) : authUser ? (
        <Box>
          <ButtonUnlogin userSignOut={userSignOut} />
          {role === "user" ? (
            <ButtonProfile setOpenProfile={setOpenProfile} />
          ) : (
            <ButtonAdminProfile setOpenAdminProfile={setOpenAdminProfile} />
          )}
        </Box>
      ) : (
        <Box>
          <ButtonSignIn openSignIn={openSignIn} setOpenSignIn={setOpenSignIn} />
          <ButtonSignUp setOpenSignUp={setOpenSignUp} />
        </Box>
      )}
    </div>
  );
};

export default AuthDetails;
