import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { Box } from "@mui/material";
import { forwardRef, useEffect, useState } from "react";
import Appeals from "./appeals/Appeals";
import UserSearch from "./searchUser/UserSearch";
import { getDatabase, onValue, ref } from "firebase/database";
import AddProduct from "./addProduct/AddProduct";
import { useForm } from "react-hook-form";
import ShowAllProducts from "./showAllProducts.jsx/ShowAllProducts";
import OrderedProducts from "./orders/OrderedProducts";
import OrderedSkeletons from "./orders/OrderedSkeletons";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}));

// анимация мод.окна
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AdminProfile = ({
  openAdminProfile,
  setOpenAdminProfile,
  allProductsInfo,
}) => {
  const classes = useStyles();
  const [openOrderedSkeletons, setOpenOrderedSkeletons] = useState(false);
  const [openAppeals, setOpenAppeals] = useState(false);
  const [openUserSearch, setOpenUserSearch] = useState(false);
  const [allOrderedSkeletons, setAllOrderedSkeletons] = useState([]);
  const [allTickets, setAllTickets] = useState([]);

  // получение ВСЕХ заказов каркасов с БД
  useEffect(() => {
    const db = getDatabase();
    const ordersId = ref(db, `orders/`);
    onValue(ordersId, (snapshot) => {
      const data = snapshot.val();
      setAllOrderedSkeletons(data);
    });
  }, []);

  // получение ВСЕХ обращений с БД
  useEffect(() => {
    const db = getDatabase();
    const ticketsId = ref(db, `tickets/`);
    onValue(ticketsId, (snapshot) => {
      const data = snapshot.val();
      setAllTickets(data);
    });
  }, []);

  return (
    <>
      <Dialog
        open={openAdminProfile}
        PaperProps={{
          style: {
            backgroundColor: "#ffffff",
            boxShadow: "none",
          },
        }}
        TransitionComponent={Transition}
        className={classes.root}
        aria-labelledby="Личный кабинет"
      >
        <DialogTitle id="user_profile">Личный кабинет</DialogTitle>
        <DialogContent>
          <Typography variant="h6" style={{ marginBottom: "10px" }}>
            Ваш email: <Button color="secondary">admin</Button>
          </Typography>
          <Box style={{ display: "grid" }}>
            <Button
              onClick={() => setOpenOrderedSkeletons(true)}
              color="secondary"
            >
              Показать заказы каркасов
            </Button>
            <Button onClick={() => setOpenAppeals(true)} color="secondary">
              Показать обращения
            </Button>
            <Button onClick={() => setOpenUserSearch(true)} color="secondary">
              Найти пользователя
            </Button>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={() => setOpenAdminProfile(false)}>
            Закрыть
          </Button>
        </DialogActions>
      </Dialog>
      <OrderedSkeletons
        openOrderedSkeletons={openOrderedSkeletons}
        setOpenOrderedSkeletons={setOpenOrderedSkeletons}
        allOrderedSkeletons={allOrderedSkeletons}
      />
      <Appeals
        openAppeals={openAppeals}
        setOpenAppeals={setOpenAppeals}
        allTickets={allTickets}
      />
      <UserSearch
        openUserSearch={openUserSearch}
        setOpenUserSearch={setOpenUserSearch}
      />
    </>
  );
};

export default AdminProfile;
