import { makeStyles } from "@material-ui/core";
import { Box, Grid } from "@mui/material";
import Header from "./header/Header";
import Orders from "./orders/Orders";
import { useEffect, useState } from "react";
import { getDatabase, onValue, ref } from "firebase/database";
import WorkerProfile from "./header/WorkerProfile";

const useStyles = makeStyles(() => ({
  boxStyle: {
    width: "100vw",
    height: "100vh",
  },
}));

const Worker = ({ userSignOut, userUid }) => {
  const classes = useStyles();
  const [allOrders, setAllOrders] = useState(null);
  const [openFullOrderInfo, setOpenFullOrderInfo] = useState(false);
  const [openWorkerProfile, setOpenWorkerProfile] = useState(false);
  const [workerAddress, setWorkerAddress] = useState("");

  // получение инфы о всех заказах
  useEffect(() => {
    const db = getDatabase();
    const orders = ref(db, `orders/`);
    onValue(orders, (snapshot) => {
      const data = snapshot.val();
      setAllOrders(data);
    });
  }, []);

    // получение адреса из профиля работника
    useEffect(() => {
      const db = getDatabase();
      const orders = ref(db, `users/${userUid}/address`);
      onValue(orders, (snapshot) => {
        const data = snapshot.val();
        setWorkerAddress(data);
      });
    }, [userUid]);

  let orders = allOrders ? Object.entries(allOrders) : null;

  const ordersSendForExecution = orders?.filter(
    (el) => el[1].status === "sendForExecution"
  );

  return (
    <>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "100vh" }}
      >
        <Box className={classes.boxStyle}>
          <Header
            userSignOut={userSignOut}
            setOpenWorkerProfile={setOpenWorkerProfile}
          />
          <Orders
            ordersSendForExecution={ordersSendForExecution}
            setOpenFullOrderInfo={setOpenFullOrderInfo}
            allOrders={allOrders}
            openFullOrderInfo={openFullOrderInfo}
            userUid={userUid}
          />
        </Box>
      </Grid>
      <WorkerProfile
        openWorkerProfile={openWorkerProfile}
        setOpenWorkerProfile={setOpenWorkerProfile}
        userUid={userUid}
        workerAddress={workerAddress}
      />
    </>
  );
};

export default Worker;
