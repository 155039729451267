import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ListItem,
  Slide,
  makeStyles,
} from "@material-ui/core";
import { List, Typography } from "@mui/material";
import React, { useState } from "react";
import { namesOfOrderFields } from "../../../../utils/namesOfOrderFields";
import { child, get, getDatabase, ref, remove, set, update } from "firebase/database";
import { Uploader } from "uploader";
import { UploadButton } from "react-uploader";
import materials from "../../../../utils/materials";
import { auth } from "../../../../config/firebase.config";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}));

// анимация мод.окна
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const OrderDescription = ({
  openOrderDescription,
  setOpenOrderDescription,
  fullOrderInfo,
  orderId,
  workerAddress,
}) => {
  const classes = useStyles();
  const [photos, setPhotos] = useState("");

  const fullOrderInfoArr = !fullOrderInfo
    ? null
    : Object.keys(fullOrderInfo).map(function (key) {
        return [key, fullOrderInfo[key]];
      });

  const fullPriceOrder = fullOrderInfo.fullPrice;
  const projectName = fullOrderInfo.name;

  const database = getDatabase();
  function orderIsReady() {

    update(ref(database, `orders/${orderId}/`), {
      status: "orderIsReady",
      address: `${workerAddress}`,
    });

    update(
      ref(database, `users/${fullOrderInfo.userUid}/userOrders/${orderId}`),
      {
        status: "orderIsReady",
      }
    );

    const idAlert = Date.now();
    const date = new Date().toLocaleString("ru", {
      timeZone: "Europe/Moscow",
    });

    set(ref(database, `users/${fullOrderInfo.userUid}/alerts/${idAlert}`), {
      date: date,
      id: "orderIsReady",
      numberOrder: orderId,
    });

    set(ref(database, `portfolio/${idAlert}/`), {
      photo: `${photos}`,
      price: `${fullPriceOrder}`,
      projectName: `${projectName}`,
    });

  }

  const uploader = Uploader({
    apiKey: "public_W142ifhCgyompcqZuQ4GV5RFNpQM",
  });
  const options = {
    apiKey: "secret_W142ifh2wgkhg2hz5SEDX7367K6R",
    maxFileCount: 1,
  };

    // удаление выполненного заказа из профиля работника
    const deleteCompletedOrder = async () => {
      const dbRef = ref(getDatabase());
  
      try {
        const orderSnapshot = await get(dbRef, `users/${auth.lastNotifiedUid}/ordersInWork/${orderId}`);
  
        if (orderSnapshot.exists()) {
          const orderRefToRemove = child(dbRef, `users/${auth.lastNotifiedUid}/ordersInWork/${orderId}`);
  
          await remove(orderRefToRemove);
          setOpenOrderDescription(false);
        }
      } catch (error) {
        console.error("Error removing order:", error);
      }
    };

  function orderReceived() {
    update(ref(database, `orders/${orderId}/`), {
      status: "orderReceived",
    });

    update(
      ref(database, `users/${fullOrderInfo.userUid}/userOrders/${orderId}`),
      {
        status: "orderReceived",
      }
    );
    deleteCompletedOrder();
    setOpenOrderDescription(false);
  }

  return (
    <Box>
      <Dialog
        open={openOrderDescription}
        disableEnforceFocus
        PaperProps={{
          style: {
            backgroundColor: "#ffffff",
            boxShadow: "none",
          },
        }}
        TransitionComponent={Transition}
        className={classes.root}
        aria-labelledby="Информация о заказе"
      >
        <DialogTitle id="order_info">Информация о заказе</DialogTitle>
        <DialogContent>
          <Typography>
            Наименование проекта: <strong>{fullOrderInfo.name}</strong>
          </Typography>
          <List className={classes.root}>
            {fullOrderInfoArr?.map((item) =>
              item[0] === "totalLength" ? (
                <ListItem key={item[0]}>{`${
                  namesOfOrderFields[item[0]]
                }: ${Object.keys(item[1]).map((key) =>
                  [
                    materials[key],
                    Object.entries(item[1][key]).join(", "),
                  ].join(": ")
                )} `}</ListItem>
              ) : item[0] === "description" ||
                item[0] === "finalTime" ||
                item[0] === "fullPrice" ||
                item[0] === "materialPrice" ||
                item[0] === "paintPrice" ||
                item[0] === "workPrice" ? (
                <ListItem key={item[0]}>{`${namesOfOrderFields[item[0]]}: ${
                  item[1]
                }; `}</ListItem>
              ) : item[0] === "photo" ? (
                <ListItem key={item[0]}>
                  {namesOfOrderFields[item[0]]}:
                  <Button
                    style={{ marginLeft: "10px" }}
                    target="_blank"
                    variant="outlined"
                    color="secondary"
                    href={item[1]}
                  >
                    Открыть
                  </Button>
                </ListItem>
              ) : null
            )}
          </List>
        </DialogContent>
        <DialogActions>
          {fullOrderInfo.status === "orderIsReady" ? (
            <Button
              color="secondary"
              variant="outlined"
               onClick={orderReceived}
            >
              Заказ передан заказчику
            </Button>
          ) : (
            <>
            <Box>
              <UploadButton
                ref={ref}
                disabled
                uploader={uploader}
                options={options}
                onComplete={(files) => {
                  setPhotos(files[0]?.fileUrl);
                }}
              >
                {({ onClick }) => (
                  <Box>
                    <Button
                      color="secondary"
                      disabled={photos?.length > 1}
                      variant="outlined"
                      onClick={onClick}
                    >
                      Добавьте фото готового заказа
                    </Button>
                  </Box>
                )}
              </UploadButton>
            </Box>
          <Button
            onClick={orderIsReady}
            color="secondary"
            variant="contained"
            disabled={photos?.length === 0}
            style={{ marginLeft: "10px" }}
          >
            Заказ готов
          </Button>
          </>
           )}
          <Button variant="text" onClick={() => setOpenOrderDescription(false)}>
            Закрыть
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default OrderDescription;
