import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Slide,
  makeStyles,
} from "@material-ui/core";
import React from "react";
import { DialogTitle } from "@mui/material";
import HelpInfo from "./HelpInfo";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}));

// анимация мод.окна
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Information = ({ openInfo, setOpenInfo }) => {
  const classes = useStyles();

  return (
    <>
      <Dialog
        open={openInfo}
        PaperProps={{
          style: {
            boxShadow: "none",
            width: "100vw",
            maxWidth: "500px",
            margin: 0,
            padding: 0,
          },
        }}
        TransitionComponent={Transition}
        className={classes.root}
        aria-labelledby="Авторизация"
      >
        <DialogTitle>Информация</DialogTitle>
        <DialogContent>
          <HelpInfo />
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={() => setOpenInfo(false)}>
            Закрыть
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Information;
