import { Box, List, makeStyles } from "@material-ui/core";
import React from "react";
import FullPrice from "./price/FullPrice";
import WorkPrice from "./price/WorkPrice";
import MaterialPrice from "./price/MaterialPrice";
import PaintPrice from "./price/PaintPrice";
import FinalTime from "./price/FinalTime";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}));

const FinalSum = ({ form, allPrices }) => {
  const classes = useStyles();
  const materialLength = form.getValues("totalLength");

  let valuesMat = Object.values(materialLength);

  // наименования материалов (выбранных пользователем)
  const keysSelectedMaterials = valuesMat
    .map((obj) => [].concat(Object.keys(obj)))
    .flat();

  // длины материалов (введенные пользователем)
  const valuesSelectedMaterials = valuesMat
    .map((obj) => [].concat(Object.values(obj)))
    .flat();

  // сумма длин всех материалов
  const finalLengthMaterials = valuesSelectedMaterials?.reduce(
    (a, b) => Number(a) + Number(b)
  );

  let allPricesValues = Object.values(allPrices);

  // цены на выбранные элементы  [925, 25, 115, 140]
  function matPrice() {
    let newArr = [];
    for (let j = 0; j < keysSelectedMaterials.length; j++) {
      for (let i = 0; i < allPricesValues.length; i++) {
        let price = allPricesValues[i][keysSelectedMaterials[j]];
        price !== undefined && newArr.push(price);
      }
    }
    return newArr;
  }

  // сумма цен выбранных материалов
  const materialPrice = Math.round(matPrice().reduce((r, a, i) => {
    return r + a * valuesSelectedMaterials[i];
  }, 0));

  // цена за работу и цена за время
  const price = allPricesValues
    .map((obj) => [].concat(Object.entries(obj)))
    .flat();
  const priceForWork = price.find((el) => el[0] === "work")[1];
  const priceFortTime = price.find((el) => el[0] === "time")[1];

  const workPrice = Math.round(
    materialPrice * 2 + finalLengthMaterials * priceForWork
  );
  const finalTime = Math.ceil(
    finalLengthMaterials / priceFortTime + materialPrice / 10000
  );
  const paintPrice = Math.ceil(workPrice / 6);
  const fullPrice = Math.round(workPrice + paintPrice + materialPrice);

  React.useEffect(() => {
    form.setValue("fullPrice", fullPrice);
    form.setValue("materialPrice", materialPrice);
    form.setValue("paintPrice", paintPrice);
    form.setValue("finalTime", finalTime);
    form.setValue("workPrice", workPrice);
  }, [fullPrice, materialPrice, paintPrice, finalTime, workPrice, form]);

  return (
    <Box>
      <List className={classes.root}>
        <FullPrice fullPrice={fullPrice} />
        <WorkPrice workPrice={workPrice} />
        <MaterialPrice materialPrice={materialPrice} />
        <PaintPrice paintPrice={paintPrice} />
        <FinalTime finalTime={finalTime} />
      </List>
    </Box>
  );
};

export default FinalSum;
