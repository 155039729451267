import {
  Checkbox,
  Chip,
  FormHelperText,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { Controller } from "react-hook-form";
import materials from "../../../../../utils/materials";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const MaterialItem = ({ item, form, allPrices }) => {
  const classes = useStyles();

  return (
    <>
      <Typography variant="h6">{materials[item]}:</Typography>
      <Controller
        control={form.control}
        rules={{ required: true }}
        name={item}
        render={({ field }) => (
          <>
            <Select
              {...field}
              className={classes.formControl}
              multiple
              input={<OutlinedInput />}
              renderValue={(selected) => (
                <div className={classes.chips}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} className={classes.chip} />
                  ))}
                </div>
              )}
              MenuProps={MenuProps}
            >
              {Object.keys(allPrices[item])?.map((mat) => (
                <MenuItem key={mat} value={mat}>
                  <Checkbox
                    checked={form.getValues(`${item}`).indexOf(mat) > -1}
                  />
                  <ListItemText primary={mat} />
                </MenuItem>
              ))}
            </Select>
            {!!form.formState.errors[item] && (
              <FormHelperText error>Поле не заполнено.</FormHelperText>
            )}
          </>
        )}
      />
    </>
  );
};

export default MaterialItem;
