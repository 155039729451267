import {
  Box,
  Chip,
  FormHelperText,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { Controller } from "react-hook-form";
import { makeStyles, Checkbox, TextField, OutlinedInput } from "@material-ui/core";
import materials from "../../../../../utils/materials";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
    margin: 0,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },

  customInputLabel: {
    "& legend": {
      visibility: "visible"
    },
  }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const MaterialsAndNameOrder = ({ form }) => {
  const classes = useStyles();
  
  return (
    <Box style={{ display: "grid", width: "300px" }}>
      <Controller
        control={form.control}
        rules={{ required: "Field is required!" }}
        name="name"
        render={({ field }) => (
          <TextField
            {...field}
            style={{width: "100%", margin: 0 }}
            label="Введите название проекта"
            variant="filled"
          />
        )}
      />
      <Typography style={{textAlign: "center", marginTop: "10px"}}>Выберите материалы:</Typography>
      <Controller
        control={form.control}
        rules={{ required: true }}
        name="selectedMaterials"
        render={({ field }) => (
          <>
            <Select
              {...field}
              className={classes.formControl}
              multiple
              displayEmpty
              input={<OutlinedInput id="select-multiple-chip"/>}
              renderValue={(selected) => (
                <div className={classes.chips}>
                  {selected.map((value) => (
                    <Chip
                      key={value}
                      label={materials[value]}
                      className={classes.chip}
                    />
                  ))}
                </div>
              )}
              MenuProps={MenuProps}
            >
              {Object.keys(materials).map((el) => (
                <MenuItem key={el} value={el}>
                  <Checkbox
                    checked={form.getValues("selectedMaterials").indexOf(el) > -1}
                  />
                  <ListItemText primary={materials[el]} />
                </MenuItem>
              ))}
            </Select>
            {!!form.formState.errors.selectedMaterials && (
              <FormHelperText error>Поле не заполнено.</FormHelperText>
            )}
          </>
        )}
      />
    </Box>
  );
};

export default MaterialsAndNameOrder;
