import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Button, CardActions } from "@mui/material";
import materials from "../../../../utils/materials";

function OrderCard({ getId, itemName, itemValue }) {
  const totalLengthArr = !itemValue.totalLength
    ? null
    : Object.keys(itemValue.totalLength).map((key) => {
        return [
          materials[key],
          Object.entries(itemValue.totalLength[key]).join("; "),
        ];
      });

  return (
    <Card sx={{ maxWidth: 345, position: "relative" }} key={itemName}>
      <CardMedia
        component="img"
        alt="image"
        height="200"
        image={itemValue.photo}
      />
      <CardContent style={{ marginBottom: "18%" }}>
        <Typography gutterBottom variant="h6" component="div">
          Наименование проекта: {itemValue.name}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Материалы:{" "}
          {totalLengthArr.map((el, i) =>
            i === 3 ? (
              <li style={{ listStyleType: "none" }} key={el[0]}>
                и т.д.
              </li>
            ) : (
              i < 3 && (
                <li key={el[0]}>
                  {el[0]}: {el[1]}
                </li>
              )
            )
          )}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Описание: {itemValue.description}
        </Typography>
        <Typography variant="body1" color="text.secondary">
          Цена: <strong>{itemValue.fullPrice} руб.</strong>
        </Typography>
      </CardContent>
      <CardActions style={{ position: "absolute", bottom: 0, left: "45%" }}>
        <Button
          size="medium"
          variant="outlined"
          color="secondary"
          id={itemName}
          onClick={getId}
        >
          Посмотреть заказ
        </Button>
      </CardActions>
    </Card>
  );
}

export default OrderCard;
