import { Box, Button } from "@mui/material";

const Header = ({ userSignOut, setOpenWorkerProfile }) => {
  return (
    <Box style={{ marginTop: "10px", marginRight: "20px" }}>
      <Button
        onClick={() => setOpenWorkerProfile(true)}
        variant="contained"
        style={{ marginRight: "10px", marginLeft: "20px" }}
      >
        Мои заказы
      </Button>
      <Button onClick={userSignOut} variant="contained" color="error">
        Выйти
      </Button>
    </Box>
  );
};

export default Header;
