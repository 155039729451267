import { Box, ListItem, ListItemText } from "@material-ui/core";
import { status } from "../../../../../../utils/orderStatus";

const OrderStatus = ({ orderStatus, price }) => {
  return (
    <Box>
      <ListItem>
        {orderStatus === "sendForPrepayment" ? (
          <ListItemText
            primary="Статус заказа:"
            secondary={`${status[orderStatus]} в размере ${price} руб.`}
          />
        ) : (
          <ListItemText
            primary="Статус заказа:"
            secondary={status[orderStatus]}
          />
        )}
      </ListItem>
    </Box>
  );
};

export default OrderStatus;
